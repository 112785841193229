<script setup lang="ts">
interface Props {
  type?: string;
  variant?: 'flat' | 'text' | 'outlined' | 'plain' | 'elevated' | 'tonal';
  disabled?: boolean;
  loading?: boolean;
  position?:
    | 'fixed'
    | 'static'
    | 'relative'
    | 'sticky'
    | 'absolute'
    | undefined;
  classNames?: string[];
  styles?: string[];
  height?: string;
  width?: string;
  title?: string;
  href?: string;
  to?: string;
  activatorProps?: any;
}
const props = defineProps<Props>();

const emits = defineEmits(['callback']);
const onClick = () => {
  emits('callback');
};
</script>

<template>
  <v-hover>
    <template #default="{ isHovering, props }">
      <v-btn
        v-bind="activatorProps"
        :color="'persian-blue'"
        :height="height"
        :width="width"
        flat
        :to="to"
        :href="href"
        :position="position"
        :style="[styles]"
        class="text-none text-white ec-text-large"
        :class="[classNames, { 'btn-shadow': !isHovering }]"
        :type="type"
        :disabled="disabled"
        :ripple="false"
        :loading="loading"
        :variant="variant"
        @click="onClick"
      >
        <slot />
        <span> {{ title }}</span>
      </v-btn>
    </template>
  </v-hover>
</template>
